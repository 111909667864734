<template>
  <v-list dense>
    <v-list-item-group>
      <v-list-item :to="{ name: 'BrokerLeadsView' }">
        <v-list-item-action>
          <v-icon>mdi-account-box-multiple-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Leads
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'BrokerPropertyView' }">
        <v-list-item-action>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Propiedades
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'BrokerPresentationView' }">
        <v-list-item-action>
          <v-icon>mdi-presentation</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Presentaciones
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div>
        <v-list-item :to="{ name: 'BrokerRealEstateDevelopmentView' }">
          <v-list-item-action>
            <v-icon>mdi-office-building</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Desarrollos Inmobiliarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      moduleQuote: localStorage.getItem("moduleQuote")
    };
  }
};
</script>
